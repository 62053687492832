import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  var onemobileDCN =
    window.screen.width > 420
      ? "8a96945901757509a7551d43776b0342"
      : "8a96945901757509a7551d6362c80344";

  const abtest = window.localStorage.getItem("abtest");

  // Define Ad unit names
  // Original
  let RightColumnUnitName = "NEXT_RightColumnHalfPage1";
  let LeftColumnUnitName = "NEXT_LeftColumnHalfPage";
  let MPUTopRightUnitName = "NEXT_MPUTopRight";
  let LeaderBelowTitleUnitName = "NEXT_Leader_BellowTitle";
  // Domain/device Specific
  if (abtest === "ab.if.com.b") {
    RightColumnUnitName = "DESK_RightColumn";
    LeftColumnUnitName = "DESK_LeftColumn";
    MPUTopRightUnitName = "MOB_MPUTopRight";
    LeaderBelowTitleUnitName = "DESK_LeaderBelowTitle";
    if (window.screen.width < 812) {
      LeaderBelowTitleUnitName = "MOB_LeaderBelowTitle";
    }
  }

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = "NEXT_RightColumnHalfPage1";
  let LeftColumnPathway = "NEXT_LeftColumnHalfPage";
  let MPUTopRightPathway = "NEXT_MPUTopRight";
  let LeaderBelowTitlePathway = "NEXT_Leader_BellowTitle";
  // Domain/device Specific
  if (abtest === "ab.tc.com.y") {
    RightColumnPathway = "TCCOM/DESK_RightColumn";
    LeftColumnPathway = "TCCOM/DESK_LeftColumn";
    MPUTopRightPathway = "TCCOM/DESK_MPUTopRight";
    LeaderBelowTitlePathway = "TCCOM/DESK_LeaderBelowTitle";
    if (window.screen.width < 812) {
      MPUTopRightPathway = "TCCOM/MOB_MPUTopRight";
      LeaderBelowTitlePathway = "TCCOM/MOB_LeaderBelowTitle";
    }
  }

  // prettier-ignore
  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        {
          bidder: 'adagio',
          params: {
              organizationId: '1067',
              site: 'trendscatchers-com',
              adUnitElementId: 'LeaderBelowTitle',
              environment: 'desktop',
              placement: `${LeaderBelowTitlePathway}`
          }
      },
      {bidder: 'adform', params: {mid: 980028}},
      //{bidder: 'appnexus', params: {placementId: '20942283'}},
      {bidder: 'criteo', params: {networkId: 8147}},
      {bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
      //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
      //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
      {bidder: 'openx', params: {unit: '542520983', delDomain: 'lqr-d.openx.net'}},
      {
          bidder: 'onemobile',
          params: {
              dcn: '8a96945901757509a7551d43776b0342',
              pos: '8a96910d0177778c7fdb8ce898f100b8',
              ext: {}
          }
      },
      {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      //{bidder: 'pubmatic',params: {publisherId: '159940', adSlot: 'com_desktop_next_Leader_bellowTitle'}},
      //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
      {bidder: 'sovrn', params: {tagid: 861241}},
      { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
      {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
      {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
      {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116575}},
      {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
      {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
      {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
      { bidder: 'sharethrough', params: {pkey: 'cre5tAJODAOkhf6UsJuTyd1K'} }
      ],
      "mobile_bidders": [
        {
          bidder: 'adagio',
          params: {
              organizationId: '1067',
              site: 'trendscatchers-com',
              adUnitElementId: 'LeaderBelowTitle',
              environment: 'mobile',
              placement: `${LeaderBelowTitlePathway}`
          }
      },
      {bidder: 'adform', params: {mid: 980034}},
      //{bidder: 'appnexus', params: {placementId: '20942283'}},
      {bidder: 'criteo', params: {networkId: 8147}},
      //{ bidder: 'districtm',  params: { placementId: 16005275 } },
      //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
      {bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
      {bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
      {bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
      //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      {
          bidder: 'onemobile',
          params: {
              dcn: '8a96945901757509a7551d6362c80344',
              pos: '8a969d8e0177778c80348ce8ab5b00c1',
              ext: {}
          }
      },
      {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      {bidder: 'openx', params: {unit: '543888597', delDomain: 'lqr-d.openx.net'}},
      // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_Leader_bellowTitle'}},
      //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
      {bidder: 'sovrn', params: {tagid: 861247}},
      //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
      {bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
      {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
      {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
      {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116595}},
      {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
      {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
      {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
      { bidder: 'sharethrough', params: {pkey: 'XE58Nk5TpmtdiIahXSkTLxp9'} },
      { bidder: 'sharethrough', params: {pkey: 'amdG5h69ShuSP1Vd8lubbJRd'} }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        {
          bidder: 'adagio',
          params: {
              organizationId: '1067',
              site: 'trendscatchers-com',
              adUnitElementId: 'MPUTopRight',
              environment: 'mobile',
              placement: `${MPUTopRightPathway}`
          }
      },
      {bidder: 'adform', params: {mid: 951115}},
      //{bidder: 'appnexus', params: {placementId: '20942284'}},
      {bidder: 'criteo', params: {networkId: 8147}},
      //{ bidder: 'districtm', params: { placementId: 16005279 } },
      //{ bidder: 'districtmDMX', params: { dmxid: 367605, memberid : 101868 } },
      {bidder: 'ix', params: {siteId: 623512, size: [300, 250]}},
      {bidder: 'ix', params: {siteId: 623512, size: [336, 280]}},
      {bidder: 'ix', params: {siteId: 623512, size: [320, 100]}},
      //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      {
          bidder: 'onemobile',
          params: {
              dcn: '8a96945901757509a7551d6362c80344',
              pos: '8a969d8e0177778c80348ce8b18a00c2',
              ext: {}
          }
      },
      {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      {bidder: 'openx', params: {unit: '543888602', delDomain: 'lqr-d.openx.net'}},
      // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_MPUTopRight'}},
      //{ bidder: 'richaudience', params: { pid: 'hoS2bUVNvb', supplyType: 'site' }},
      {bidder: 'sovrn', params: {tagid: 861249}},
      //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
      {bidder: 'teads', params: {pageId: '121182', placementId: '135711'}},
      {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUTopRight_Prebid"}},
      {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
      {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116599}},
      {bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId: '1490051' }},
      {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
      {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
      { bidder: 'sharethrough', params: {pkey: 'lAQukJrXSRC57vBGjUi3DoyW'} },
      { bidder: 'sharethrough', params: {pkey: 'THOpsU5gkUezzn22FsCIBtrx'} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600],[160, 600]] : [[160, 600]],
      "bidders": [
        {
          bidder: 'adagio',
          params: {
              organizationId: '1067',
              site: 'trendscatchers-com',
              adUnitElementId: 'RightColumn',
              environment: 'desktop',
              placement: `${RightColumnPathway}`
          }
      },
      {bidder: 'adform', params: {mid: 997239}},
      {bidder: 'sovrn', params: {tagid: 866901}},
      {bidder: 'sovrn', params: {tagid: 934828}},
     // {bidder: 'appnexus', params: {placementId: '20942290'}},
      {bidder: 'criteo', params: {networkId: 8147}},
      //{ bidder: 'districtm', params: { placementId: 16005296 } },
      //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
      {bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
      //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      {
          bidder: 'onemobile',
          params: {
              dcn: onemobileDCN,
              pos: '8a9694a00178782103332106bdfe0005',
              ext: {}
          }
      },
      {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      {bidder: 'openx', params: {unit: '543888594', delDomain: 'lqr-d.openx.net'}},
      // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUTopRight1'}},
      //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
      {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},

      { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
      {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
      {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116582}},
      {bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
      {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
      {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
      { bidder: 'sharethrough', params: {pkey: 'HzlbWCuAiUtUAT48iDaVZogC'} }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeftColumn",
      "name": LeftColumnUnitName,
      "path": `/76730613/${LeftColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
        {
          bidder: 'adagio',
          params: {
              organizationId: '1067',
              site: 'trendscatchers-com',
              adUnitElementId: 'LeftColumn',
              environment: 'desktop',
              placement: `${LeftColumnPathway}`
          }
      },
      {bidder: 'adform', params: {mid: 1088951}},
      {bidder: 'criteo', params: {networkId: 347220}},
      {bidder: 'ix', params: {siteId: 697578, size: [160, 600]}},
      {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      {bidder: 'sovrn', params: {tagid: 861245}},
      {bidder: 'sovrn', params: {tagid: 861246}},
      //{bidder: 'appnexus', params: {placementId: '22129020'}},
      {bidder: 'openx', params: {unit: '543986374', delDomain: 'lqr-d.openx.net'}},
      // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'trendscatchers.com_desktop_NEXT_LeftColumnHalfPage'}},
      { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_LeftColumnHalfPage_Prebid"} },
      {
          bidder: 'onemobile',
          params: {
              dcn: onemobileDCN,
              pos: '8a969d33017a7af1bd7df774192b01b6',
              ext: {}
          }
      },
      {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
      {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
      {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116607}},
      {bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1490051' }},
      {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
      {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
      { bidder: 'sharethrough', params: {pkey: 'RJLXOvgsfpFxI9MHxoZCRo8D'} }
      ]
    }
  ];
}
