import { DebugModeLog } from "../utils";

export const TaboolaCPCValue = () => {
    const CryptoJS = require("crypto-js");
    const base64url = require('base64url');
    const value = localStorage.getItem( 'value' );
    const unique_key = localStorage.getItem( 'unique_key' );
    const decrypted = decrypt( unique_key, value );
    DebugModeLog('Decrypt Key: ' + unique_key);
    DebugModeLog('CPC Value: ' + decrypted);

    return decrypted;

    function decrypt(key, cpcValue){
 
        // Handle a case where the value was *not* encrypted:
        if (!isNaN(cpcValue)) {
            return parseFloat(cpcValue)
        }
     
        // Handle the regular case (value *was* encrypted):
        let keyBytes = CryptoJS.enc.Hex.parse(key);
        let decrypt = CryptoJS.AES.decrypt(base64url.toBase64(decodeURIComponent(cpcValue)), keyBytes, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
        let decryptedStr = CryptoJS.enc.Utf8.stringify(decrypt).toString();
        // 1.23_1612281007670
        return parseFloat(decryptedStr.split('_')[0]);
    }
}
