import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // prettier-ignore
  let adUnits = [
    {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderAboveTitle",
        "name": "NEXT_LeaderAboveTitle",
        "path": "/76730613/tccom/desk_next_LeaderAboveTitle",
        "sizes": window.screen.width > 720 ? 
        [[1170, 280], [728, 90], [970, 250], [970, 90]] 
          : 
        [[300, 250], [336, 280], [250, 250], [300, 100], [320,100], [200, 200]],
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": [
          {
            bidder: 'adagio',
            params: {
                organizationId: '1067',
                site: 'trendscatchers-com',
                adUnitElementId: 'LeaderBelowTitle',
                environment: 'desktop',
                placement: 'NEXT_Leader_BellowTitle',
            }
          },
          {bidder: 'adform', params: {mid: 980028}},
          //{bidder: 'appnexus', params: {placementId: '20942283'}},
          {bidder: 'criteo', params: {networkId: 8147}},
          {bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
          ////{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          //{ bidder: 'districtm',  params: {  placementId: 16005275 } },
          //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
          {bidder: 'openx', params: {unit: '542520983', delDomain: 'lqr-d.openx.net'}},
          {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          //{bidder: 'pubmatic',params: {publisherId: '159940', adSlot: 'com_desktop_next_Leader_bellowTitle'}},
          //{ bidder: 'richaudience', params: { pid: 'QReieyrtGC', supplyType: 'site' }},
          {bidder: 'sovrn', params: {tagid: 861241}},
          { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
          {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
          {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
          {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116575}},
          {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
          { bidder: 'sharethrough', params: {pkey: 'cre5tAJODAOkhf6UsJuTyd1K'} }
        ],
        "mobile_bidders": [
          {
            bidder: 'adagio',
            params: {
                organizationId: '1067',
                site: 'trendscatchers-com',
                adUnitElementId: 'LeaderBelowTitle',
                environment: 'mobile',
                placement: 'NEXT_Leader_BellowTitle',
            }
          },
          {bidder: 'adform', params: {mid: 980034}},
          //{bidder: 'appnexus', params: {placementId: '20942283'}},
          {bidder: 'criteo', params: {networkId: 8147}},
          //{ bidder: 'districtm',  params: { placementId: 16005275 } },
          //{ bidder: 'districtmDMX',  params: { dmxid: 367604, memberid : 101868 } },
          {bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
          {bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
          {bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
          ////{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          {bidder: 'openx', params: {unit: '543888597', delDomain: 'lqr-d.openx.net'}},
          // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_Leader_bellowTitle'}},
          //{ bidder: 'richaudience', params: { pid: '6yQQzMZSfj', supplyType: 'site' }},
          {bidder: 'sovrn', params: {tagid: 861247}},
          //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          {bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
          {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
          {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
          {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116595}},
          {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
          { bidder: 'sharethrough', params: {pkey: 'XE58Nk5TpmtdiIahXSkTLxp9'} },
          { bidder: 'sharethrough', params: {pkey: 'amdG5h69ShuSP1Vd8lubbJRd'} }
        ]
      },
      {
        "condition": window.screen.width > 768,
        "id": "RightColumn",
        "name": "NEXT_RightColumnHalfPage1",
        "path": "/76730613/NEXT_RightColumnHalfPage1",
        "sizes": window.screen.width > 1360 ? [[300, 600], [160, 600], [120,600], [300,250], [336,280]] : [[160, 600],[120,600]],
        "bidders": [
          {
            bidder: 'adagio',
            params: {
                organizationId: '1067',
                site: 'trendscatchers-com',
                adUnitElementId: 'RightColumn',
                environment: 'desktop',
                placement: 'NEXT_RightColumnHalfPage1'
            }
          },
          {bidder: 'adform', params: {mid: 997239}},
          {bidder: 'sovrn', params: {tagid: 866901}},
          {bidder: 'sovrn', params: {tagid: 934828}},
        // {bidder: 'appnexus', params: {placementId: '20942290'}},
          {bidder: 'criteo', params: {networkId: 8147}},
          //{ bidder: 'districtm', params: { placementId: 16005296 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367608, memberid : 101868 } },
          {bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
          ////{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          {bidder: 'openx', params: {unit: '543888594', delDomain: 'lqr-d.openx.net'}},
          // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUTopRight1'}},
          //{ bidder: 'richaudience', params: { pid: 'HOpAKTXHmc', supplyType: 'site' }},
          {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},

          { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
          {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
          {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116582}},
          {bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
          {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
          { bidder: 'sharethrough', params: {pkey: 'HzlbWCuAiUtUAT48iDaVZogC'} }
        ]
      },
      {
        "condition": window.screen.width > 768 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeftColumn",
        "name": "NEXT_LeftColumnHalfPage",
        "path": "/76730613/NEXT_LeftColumnHalfPage",
        "sizes": window.screen.width > 1360 ? [[300, 600], [160, 600], [120,600], [300,250], [336,280]] : [[160, 600],[120,600]],
        "bidders": [
          {
            bidder: 'adagio',
            params: {
                organizationId: '1067',
                site: 'trendscatchers-com',
                adUnitElementId: 'LeftColumn',
                environment: 'desktop',
                placement: 'NEXT_LeftColumnHalfPage'
            }
          },
          {bidder: 'adform', params: {mid: 1088951}},
          {bidder: 'criteo', params: {networkId: 347220}},
          {bidder: 'ix', params: {siteId: 697578, size: [160, 600]}},
          {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          {bidder: 'sovrn', params: {tagid: 861245}},
          {bidder: 'sovrn', params: {tagid: 861246}},
          //{bidder: 'appnexus', params: {placementId: '22129020'}},
          {bidder: 'openx', params: {unit: '543986374', delDomain: 'lqr-d.openx.net'}},
          // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'trendscatchers.com_desktop_NEXT_LeftColumnHalfPage'}},
          { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_LeftColumnHalfPage_Prebid"} },
          {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
          {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
          {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116607}},
          {bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId: '1490051' }},
          {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
          { bidder: 'sharethrough', params: {pkey: 'RJLXOvgsfpFxI9MHxoZCRo8D'} }
        ]
      },
      {
        "id": "MPUCenterRight",
        "name": "NEXT_MPUCenterRight",
        "path": "/76730613/NEXT_MPUCenterRight",
        "sizes": [[300, 250],[336,280]],
        "bidders": [
          {
            bidder: 'adagio',
            params: {
                organizationId: '1067',
                site: 'trendscatchers-com',
                adUnitElementId: 'MPUCenterRight',
                environment: 'desktop',
                placement: 'NEXT_MPUCenterRight'
                
            }
          },
          {bidder: 'adform', params: {mid: 980030}},
          // {bidder: 'appnexus', params: {placementId: '20942285'}},
          {bidder: 'criteo', params: {networkId: 8147}},
          {bidder: 'ix', params: {siteId: 623504, size: [300, 250]}},
          {bidder: 'ix', params: {siteId: 623504, size: [336, 280]}},
          //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          //{ bidder: 'districtm',  params: { placementId: 16005292 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
          {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          {bidder: 'openx', params: {unit: '543888589', delDomain: 'lqr-d.openx.net'}},
          // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUCenterRight'}},
          //{ bidder: 'richaudience', params: { pid: 'i6doEjb0Cb', supplyType: 'site' }},
          {bidder: 'sovrn', params: {tagid: 861238}},
          {bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
          {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUCenterRight_Prebid"}},
          {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
          {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116580}},
          {bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
          {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
          { bidder: 'sharethrough', params: {pkey: 'AnxysNzleld51ucuUXnbajT1'} }
        ],
        "mobile_bidders": [
          {
            bidder: 'adagio',
            params: {
                organizationId: '1067',
                site: 'trendscatchers-com',
                adUnitElementId: 'MPUCenterRight',
                environment: 'mobile',
                placement: 'NEXT_MPUCenterRight'
            }
        },
        {bidder: 'adform', params: {mid: 980036}},
        //  {bidder: 'appnexus', params: {placementId: '20942285'}},
        {bidder: 'criteo', params: {networkId: 8147}},
        {bidder: 'ix', params: {siteId: 623524, size: [300, 250]}},
        {bidder: 'ix', params: {siteId: 623524, size: [336, 280]}},
        {bidder: 'ix', params: {siteId: 623524, size: [320, 100]}},
        //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
        //{ bidder: 'districtm',  params: { placementId: 16005292 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367606, memberid : 101868 } },
        {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        {bidder: 'openx', params: {unit: '543888599', delDomain: 'lqr-d.openx.net'}},
        // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_next_MPUCenterRight'}},
        //{ bidder: 'richaudience', params: { pid: 'QLncydO1NV', supplyType: 'site' }},
        {bidder: 'sovrn', params: {tagid: 861248}},
        //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
        {bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
        //{bidder: 'ogury', params: { assetKey: 'OGY-D61575877C9B', adUnitId: 'c6ce0c30-17e3-013b-8433-3794722aeb85', skipSizeCheck: true }},
        {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUCenterRight_Prebid"}},
        {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
        {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116597}},
        {bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
        {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: 'sharethrough', params: {pkey: 'oP2hQEMs5U3gUciHxqh6jQor'} },
        { bidder: 'sharethrough', params: {pkey: 'd9yDPWOD8lYFAh1rqnNNIBjM'} }
      ]
      },
      {
        "id": "MPUBelowNext",
        "name": "NEXT_MPUBelowNext",
        "path": "/76730613/NEXT_MPUBelowNext",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[300, 250],[336,280]],
        "bidders": [
          {
            bidder: 'adagio',
            params: {
                organizationId: '1067',
                site: 'trendscatchers-com',
                adUnitElementId: 'MPUBelowNext',
                environment: 'desktop',
                placement: 'NEXT_MPUBelowNext'
            }
          },
          {bidder: 'adform', params: {mid: 980029}},
          //{bidder: 'appnexus', params: {placementId: '20942291'}},
          {bidder: 'criteo', params: {networkId: 8147}},
          {bidder: 'ix', params: {siteId: 623497, size: [300, 250]}},
          {bidder: 'ix', params: {siteId: 623497, size: [336, 280]}},
          ////{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
          //{ bidder: 'districtm', params: {placementId: 16152397} },
          //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
          {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
          {bidder: 'openx', params: {unit: '543888587', delDomain: 'lqr-d.openx.net'}},
          // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_next_MPUBelowNext'}},
          //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
          {bidder: 'sovrn', params: {tagid: 861242}},
          {bidder: 'teads', params: {pageId: '121182', placementId: '135712'}},
          {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_MPUBelowNext_Prebid"}},
          {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
          {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116579}},
          {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
          {bidder: 'sharethrough', params: {pkey: 'hKy7dlHJAhx1YuH3GjsnFAhp'}}
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'trendscatchers-com', adUnitElementId: 'MPUBelowNext', environment:'mobile', placement:'NEXT_MPUBelowNext'} },
          { bidder: 'adform', params: { mid: 980035 } },
         // { bidder: 'appnexus', params: {placementId: '20942291'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 671485, size:[300, 250]} },
          { bidder: 'ix', params: {siteId: 671485, size:[336, 280]} },
          //{ bidder: 'justpremium', params: {zone: 115712} },
          //{ bidder: 'districtm', params: {placementId: 16152397} },
          //{ bidder: 'districtmDMX', params: {dmxid: 374438, memberid : 101868} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'openx', params: {unit: '543888607', delDomain: 'lqr-d.openx.net'} },
          // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'com_mobile_next_MPUBelowNext' } },
          //{ bidder: 'richaudience', params: { pid: 'EGWgVO2XQq', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 934674 } },
          { bidder: 'sovrn', params:{ tagid: 934675 } },
          //{ bidder: 'sublime', params:{ zoneId: 33294 , notifyId: (function b(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)})(); return window.sublime.notifyId;})() } },
          { bidder: 'teads', params: { pageId: '121182', placementId: '131481'} },
          { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_mobile_NEXT_MPUBelowNext_Prebid"} },
          {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
          {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116596}},
          {bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
          {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
          {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
          { bidder: 'sharethrough', params: {pkey: 'ybbkbdE7QDD0XwVHK5HWkqzA'} },
          { bidder: 'sharethrough', params: {pkey: 'gTluYeIQd3WS15NZTgDyCMcv'} }
        ]
      }
    ];

  // prettier-ignore
  if (window.screen.width > 1360) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 623502, size:[300, 600]} });
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 623502, size:[300, 250]} });
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 623502, size:[336, 280]} });

      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 697578, size:[300, 600]} });
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 697578, size:[300, 250]} });
      lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 697578, size:[336, 280]} });
    }
  return adUnits;
}
