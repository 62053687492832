import { isBrowser, checkUtmSourceStartsWith, isMobile } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");

  // The current template
  const template = "NEXT";
  // Check device type
  let device = "";
  device = isMobile() ? "MOB" : "DESK";

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = `${template}_RightColumnHalfPage1`;
  let LeaderBelowTitlePathway = `${template}_Leader_BellowTitle`;

  // Domain/device Specific
  if (abtest === "ab.tc.com.y") {
    RightColumnPathway = `TCCOM/${device}_${template}_RightColumn`;
    LeaderBelowTitlePathway = `TCCOM/${device}_${template}_LeaderBelowTitle`;
  }

  // prettier-ignore
  const bidders = [
    { bidder: 'adagio', params: { organizationId: '1067', site: 'trendscatchers-com', adUnitElementId: 'LeaderBelowTitle', environment: 'desktop', placement: `${LeaderBelowTitlePathway}`}},
    { bidder: 'adform', params: {mid: 980028}},
    { bidder: 'criteo', params: {networkId: 8147}},
    { bidder: 'ix', params: {siteId: 623506, size: [728, 90]}},
    { bidder: 'openx', params: {unit: '542520983', delDomain: 'lqr-d.openx.net'}},
    { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
    { bidder: 'sovrn', params: {tagid: 861241}},
    { bidder: 'teads', params: { pageId: '113724', placementId: '135706'} },
    { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
    { bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116575}},
    { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
    { bidder: 'sharethrough', params: {pkey: 'cre5tAJODAOkhf6UsJuTyd1K'} }
  ];

  // prettier-ignore
  const mobileBidders = [
    { bidder: 'adagio', params: { organizationId: '1067', site: 'trendscatchers-com', adUnitElementId: 'LeaderBelowTitle', environment: 'mobile', placement: `${LeaderBelowTitlePathway}` }},
    { bidder: 'adform', params: {mid: 980034}},
    { bidder: 'criteo', params: {networkId: 8147}},
    { bidder: 'ix', params: {siteId: 623528, size: [300, 250]}},
    { bidder: 'ix', params: {siteId: 623528, size: [336, 280]}},
    { bidder: 'ix', params: {siteId: 623528, size: [320, 100]}},
    { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
    { bidder: 'openx', params: {unit: '543888597', delDomain: 'lqr-d.openx.net'}},
    { bidder: 'sovrn', params: {tagid: 861247}},
    { bidder: 'teads', params: {pageId: '121182', placementId: '135710'}},
    { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_NEXT_Leader_bellowTitle_Prebid"}},
    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
    { bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116595}},
    { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId: '1490051' }},
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
    { bidder: 'sharethrough', params: {pkey: 'XE58Nk5TpmtdiIahXSkTLxp9'} },
    { bidder: 'sharethrough', params: {pkey: 'amdG5h69ShuSP1Vd8lubbJRd'} }
  ];

  // prettier-ignore
  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 1024 ? [[728, 90]] : [[970, 90]],
      "bidders": bidders,
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_') && window.screen.width < 812,
      "id": "LeaderBelowTitle_1",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle_2",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle_4",
      "name": "NEXT_Leader_BellowTitle",
      "path": "/76730613/NEXT_Leader_BellowTitle",
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
      "mobile_bidders": mobileBidders,
    },
    {
      "condition": window.screen.width > 812,
      "id": "RightColumn",
      "name": "NEXT_RightColumnHalfPage1",
      "path": "/76730613/NEXT_RightColumnHalfPage1",
      "sizes": window.screen.width > 1440 ? [[300, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'trendscatchers-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` }},
        { bidder: 'adform', params: {mid: 997239}},
        { bidder: 'sovrn', params: {tagid: 866901}},
        { bidder: 'sovrn', params: {tagid: 934828}},
        { bidder: 'criteo', params: {networkId: 8147}},
        { bidder: 'ix', params: {siteId: 623502, size: [160, 600]}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '543888594', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        { bidder: 'triplelift', params: { inventoryCode: "TrendscatchersCOM_desktop_NEXT_RightColumnHalfPage_Prebid"} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
        { bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116582}},
        { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: 'sharethrough', params: {pkey: 'HzlbWCuAiUtUAT48iDaVZogC'} }
      ]
    }
  ];
}
