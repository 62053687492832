import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");

  // Define Ad unit names
  // Original
  let RightColumnUnitName = "RightColumn1";
  let LeaderDesktopInfiniteUnitName = "LeaderDesktopInfinite";
  let MPUCenterRightUnitName = "MPUCenterRight";
  let MPUParallaxUnitName = "MPU_Parallax";

  // Domain/device Specific
  if (abtest === "ab.tc.com.y") {
    RightColumnUnitName = "DESK_RightColumn";
    LeaderDesktopInfiniteUnitName = "DESK_LeaderDesktopInfinite";
    MPUCenterRightUnitName = "MOB_MPUCenterRight";
    MPUParallaxUnitName = "MOB_MPUParallax";
  }

  // Define Ad Unit Pathways
  // Original
  let RightColumnPathway = "RightColumn1";
  let LeaderDesktopInfinitePathway = "LeaderDesktopInfinite";
  let MPUCenterRightPathway = "MPUCenterRight";

  let MPUParallaxPathway = "MPU_Parallax";

  // Domain/device Specific
  if (abtest === "ab.tc.com.y") {
    RightColumnPathway = "TCCOM/DESK_RightColumn";
    LeaderDesktopInfinitePathway = "TCCOM/DESK_LeaderDesktopInfinite";
    MPUCenterRightPathway = "TCCOM/DESK_MPUCenterRight";

    if (window.screen.width < 812) {
      MPUCenterRightPathway = "TCCOM/MOB_MPUCenterRight";
      MPUParallaxPathway = "TCCOM/MOB_MPUParallax";
    }
  }

  // prettier-ignore
  let adUnits = [


    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('fb-') && !checkUtmSourceStartsWith('twtr_'),
      "id": "RightColumn",
      "name": `${RightColumnUnitName}`,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1100 ? [[300, 600], [160, 600],[300,250], [336,280], [320,50], [320,100], [300,100], [300,50], [320,480]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId: '1067', site: 'trendscatchers-com', adUnitElementId: 'RightColumn', environment: 'desktop', placement: `${RightColumnPathway}` } },
        //{bidder: 'appnexus', params: {placementId: '13715698'}},
        { bidder: 'adform', params: {mid: 997240}},
        { bidder: 'criteo', params: {networkId: 8147}},
        { bidder: 'ix', params: {siteId: 612746, size: [160, 600]}},
        // //{bidder: 'justpremium', params: {zone: 115712}},
        { bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
        { bidder: 'openx', params: {unit: '542520987', delDomain: 'lqr-d.openx.net'}},
        // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_MPUTopRight1'}},
        //{ bidder: 'richaudience', params: { pid: 'CGbrucxlFe', supplyType: 'site' }},
        { bidder: 'sovrn', params: {tagid: 866902}},
        { bidder: 'sovrn', params: {tagid: 934827}},
        { bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
        //  { bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_RightColumnHalfPage_Prebid"} }
        { bidder: 'triplelift', params: {inventoryCode: " TrendscatchersCOM_desktop_RightColumnHalfPage_Prebid"}},
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
        { bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116584}},
        { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: 'sharethrough', params: {pkey: 'SonXWQUe20158HtC2P2M8i4e'} },
        { bidder: 'sharethrough', params: {pkey: 'cyWDxzBJJ9anpCnNVNtEhvke'} },
        { bidder: 'sharethrough', params: {pkey: 'HheYkhCemCaBTrLlfu11YcQD'} }
      ],
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUCenterRight",
      "name": MPUCenterRightUnitName,
      "path": `/76730613/${MPUCenterRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100],[1,1]],
      "mobile_bidders": [
        {
          bidder: 'adagio',
          params: {
              organizationId: '1067',
              site: 'trendscatchers-com',
              adUnitElementId: 'MPUCenterRight',
              environment: 'mobile',
              placement: `${MPUCenterRightPathway}`
          }
      },
     // {bidder: 'appnexus', params: {placementId: '13715695'}},
      {bidder: 'adform', params: {mid: 951114}},
      {bidder: 'criteo', params: {networkId: 8147}},
      {bidder: 'ix', params: {siteId: 612753, size: [300, 250]}},
      {bidder: 'ix', params: {siteId: 612753, size: [336, 280]}},
      {bidder: 'ix', params: {siteId: 538242, size: [320, 100]}},
      //{bidder: 'justpremium', params: {zone: 115712, allow: ["is"]}},
      {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      {bidder: 'openx', params: {unit: '542520985', delDomain: 'lqr-d.openx.net'}},
      // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_mobile_MPUCenterRight'}},
      //{ bidder: 'richaudience', params: { pid: 'DxgLRqBxjs', supplyType: 'site' }},
      {bidder: 'sovrn', params: {tagid: 785886}},
      {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
      {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_MPUCenterRight_Prebid"}},
      //{bidder: 'ogury', params: { assetKey: 'OGY-D61575877C9B', adUnitId: 'c6ce0c30-17e3-013b-8433-3794722aeb85', skipSizeCheck: true }},
      {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
      {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116592}},
      {bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId: '1490051' }},
      {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
      {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
      { bidder: 'sharethrough', params: {pkey: '1PEhwMwJn1pViNdr6aCo3WSq'} },
      { bidder: 'sharethrough', params: {pkey: '9QEGeIhE0Hr5nZFY6aGqBZZQ'} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "LeaderDesktopInfinite",
      "name":  LeaderDesktopInfiniteUnitName,
      "path": `/76730613/${LeaderDesktopInfinitePathway}`,
      "sizes": [[728,90]],
      "bidders": [
        {
          bidder: 'adagio',
          params: {
              organizationId: '1067',
              site: 'trendscatchers-com',
              adUnitElementId: 'LeaderDesktopInfinite',
              environment: 'desktop',
              placement: `${LeaderDesktopInfinitePathway}`
          }
      },
     // {bidder: 'appnexus', params: {placementId: '20295574'}},
      {bidder: 'adform', params: {mid: 996956}},
      {bidder: 'criteo', params: {networkId: 8147}},
      {bidder: 'ix', params: {siteId: 639600, size: [728, 90]}},
      //{ bidder: 'ix', params: { siteId: 538242, size: [300, 100] }},
      // //{bidder: 'justpremium', params: {zone: 115712}},
      //{ bidder: 'districtm', params: { placementId: 19614578}},
      {bidder: 'onetag', params: {pubId: '654b33df39ee492'}},
      {bidder: 'openx', params: {unit: '542520996', delDomain: 'lqr-d.openx.net'}},
      // {bidder: 'pubmatic', params: {publisherId: '159940', adSlot: 'com_desktop_LeaderDesktopInfinite'}},
      //{ bidder: 'richaudience', params: { pid: 'EOOmDSzb4r', supplyType: 'site' }},
      {bidder: 'sovrn', params: {tagid: 866900}},
      {bidder: 'teads', params: {pageId: '121182', placementId: '131481'}},
      {bidder: 'triplelift', params: {inventoryCode: "TrendscatchersCOM_Leader_bellowTitle_Prebid"}},
      {bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
      {bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116570}},
      {bidder: 'taboola', params: { tagId: 'LeaderDesktopInfinite', publisherId: '1490051' }},
      {bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
      {bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
      { bidder: 'sharethrough', params: {pkey: 'A934J33l38Zl8pfCcSHXMyWr'} }
      
      ],
    },
    {
      "condition": false,
      "id": "MPU_Parallax",
      "name": MPUParallaxUnitName,
      "path": `/76730613/${MPUParallaxPathway}`,
      "sizes": [[300,600], [300,250], [336,280], [320,50], [160,600], [120,600], [320,480]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'trendscatchers-com', adUnitElementId: 'MPU_Parallax', environment:'mobile', placement:`${MPUParallaxPathway}` } },
        { bidder: 'adform', params: { mid: 1129598 } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'appnexus', params: {placementId: '22738661'} },
        // { bidder: 'openx', params: { unit: '545673781', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'ix', params: {siteId: 715016, size: [320,480]} },
        { bidder: 'ix', params: {siteId: 715016, size: [300,250]} },
        { bidder: 'ix', params: {siteId: 715016, size: [300,600]} },
        { bidder: 'ix', params: {siteId: 715016, size: [160,600]} },
        { bidder: 'ix', params: {siteId: 715016, size: [320,50]} },
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'openx', params: { unit: '542520989', delDomain: 'lqr-d.openx.net'}},
        { bidder: 'sovrn', params:{ tagid: 958574 }},
        { bidder: 'sovrn', params:{ tagid: 958575 }},
        { bidder: 'sovrn', params:{ tagid: 958576 }},
        { bidder: 'sovrn', params:{ tagid: 958577 }},
        { bidder: 'sovrn', params:{ tagid: 958578 }},
        // { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tc_com_mobile_parallax' } },
        // { bidder: 'teads', params: { pageId: '121182', placementId: '135713'} },
        { bidder: 'teads', params: { pageId: '121182', placementId: '131481'} },
        { bidder: 'triplelift', params: {inventoryCode: 'TrendscatchersCOM_mobile_MPUParallax_Prebid'} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'}},
        { bidder: 'smartadserver', params: { siteId: 536182, pageId: 1662532, formatId: 116602}},
        { bidder: 'taboola', params: { tagId: 'MPU_Parallax', publisherId: '1490051' }},
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847479' }},
        { bidder: 'sharethrough', params: {pkey: 'GxE6xUtah3bdKxqYAqXJCxta'} },
        { bidder: 'sharethrough', params: {pkey: 'gStrjCgLRKNr9FO4eFZJ71pE'} },
        { bidder: 'sharethrough', params: {pkey: 'SRoW29ZAntCHzfAX4p7T7pGn'} },
        { bidder: 'sharethrough', params: {pkey: 'kTGp4L8rVW0utl4eE0f3ZnQo'} },
        { bidder: 'sharethrough', params: {pkey: 'PNgnsCk5iMMjEvLQASv53SOe'} },
        { bidder: 'sharethrough', params: {pkey: 'Z9FKHDGOcrpzhWRuQ3v6lzzs'} },
      ],
    }
  ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1100) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      // Right
      rcMissingIX.bidders.push(
        { bidder: 'ix', params: {siteId: 612746, size:[300, 600]} },
        { bidder: 'ix', params: {siteId: 612746, size:[300, 250]} },
        { bidder: 'ix', params: { siteId: 612746, size: [300, 600]} },
        { bidder: 'ix', params: { siteId: 612746, size: [300, 250]} },
        { bidder: 'ix', params: { siteId: 612746, size: [336, 280] }},
        { bidder: 'ix', params: { siteId: 612746, size: [320, 50] }},
        { bidder: 'ix', params: { siteId: 612746, size: [320, 100] }},
        { bidder: 'ix', params: { siteId: 612746, size: [300, 100] }},
        { bidder: 'ix', params: { siteId: 612746, size: [300, 50] }},
        { bidder: 'ix', params: { siteId: 612746, size: [320, 480] }}
      );
    }
  return adUnits;
}
