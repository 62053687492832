/*
Boons Media Refresh v 1.2
*/

import { DebugModeLog } from "../utils";

let boonsRefreshRunning = false;

export const boonsRefresh = () => {
  DebugModeLog("CR: 🔵 Boons Refresh Started! 🔵");

  if (!boonsRefreshRunning) {
    // Start custom adunit refresh based on slots rendered and/or impressionViewable
    window.googletag
      .pubads()
      .addEventListener("impressionViewable", (event) => {
        const slot = event.slot;
        DebugModeLog("CR: " + slot.getSlotElementId() + ": is viewable");
        for (let key in timerTracker) {
          if (timerTracker.hasOwnProperty(key)) {
            if (key === slot.getSlotElementId()) {
              timerTracker[key].isViewable = true;
            }
          }
        }
        checkReadyToStart();
      });

    window.googletag
      .pubads()
      .addEventListener("slotRenderEnded", function (event) {
        const slot = event.slot;
        DebugModeLog("CR: " + slot.getSlotElementId() + ": is rendered");

        let adUnitNamesBeingRefreshed = Object.keys(timerTracker);

        if (adUnitNamesBeingRefreshed.includes(slot.getSlotElementId())) {
          for (let key in timerTracker) {
            if (timerTracker.hasOwnProperty(key)) {
              if (key === slot.getSlotElementId()) {
                timerTracker[key].isRendered = true;
                if (!isStickyAd(key)) {
                  observer.observe(document.getElementById(key));
                }

                timerTracker[key].isEmpty = event.isEmpty;
              }
            }
          }
          renderedSlots.push(slot.getSlotElementId());

          checkReadyToStart();
        } else {
          // Set up timerTracker for infinite scroll adUnits
          timerTracker[slot.getSlotElementId()] = Object.assign(
            {},
            {
              isRendered: true,
              isEmpty: event.isEmpty,
              isViewable: false,
              isTimerStarted: false,
              isIntersecting: false,
              isScrollable: true,
              timesVisible: 0,
              requestManager: {
                a9: false,
                prebid: false,
                request: false,
              },
            }
          );
          if (!isStickyAd(slot.getSlotElementId())) {
            observer.observe(document.getElementById(slot.getSlotElementId()));
          }
          renderedSlots.push(slot.getSlotElementId());
          checkReadyToStart();
        }
      });

    // Handle page visibility change
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        DebugModeLog("CR: (v4) User Moved away from page 📵");
        isPageVisible = false;
      } else {
        DebugModeLog("CR: (v4) User came back to page 💚");
        isPageVisible = true;
      }
    });

    // Create observer for non-Sticky ads
    var observer = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          var slotID = entry.target.id;
          timerTracker[slotID].isIntersecting = entry.isIntersecting;
          if (entry.isIntersecting) {
            DebugModeLog(`CR: ${slotID}: is intersecting`);
            timerTracker[slotID].timesVisible++;
            checkReadyToStart();
          } else {
            //Reset refresh
            timerTracker[slotID].isRendered = false;
            timerTracker[slotID].isEmpty = false;
            timerTracker[slotID].isViewable = false;
            timerTracker[slotID].isTimerStarted = false;
            if (!isStickyAd(slotID)) {
              timerTracker[slotID].isIntersecting = false;
            }
            DebugModeLog(`CR: ${slotID}: Reset timerTracker`);
          }
        });
      },
      {
        threshold: 0.5,
        rootMargin: "0px 0px 0px 0px",
      }
    );
    boonsRefreshRunning = true;
  }

  const bidTimeout = 3000;
  let previousBiddersLength = 0;
  let defaultDelay = 13; //seconds
  let longDelay = 33; //seconds

  if (window.innerWidth > 812) {
    defaultDelay = 11; //seconds
    longDelay = 31; //seconds
  }

  let isPageVisible = true;
  let refreshedAdUnits = {};

  let renderedSlots = [];
  let timerTracker = {};
  for (let windowSlot in window.ads) {
    timerTracker[windowSlot] = Object.assign(
      {},
      {
        isRendered: false,
        isEmpty: false,
        isViewable: false,
        isTimerStarted: false,
        isIntersecting: false,
        timesVisible: 0,
        requestManager: {
          a9: false,
          prebid: false,
          request: false,
        },
      }
    );
  }

  // Add isScrollable
  for (let key in timerTracker) {
    if (timerTracker.hasOwnProperty(key)) {
      if (isStickyAd(key)) {
        timerTracker[key].isScrollable = false;
        timerTracker[key].isIntersecting = true;
      } else {
        timerTracker[key].isScrollable = true;
      }
    }
  }

  function isStickyAd(adUnit) {
    return (
      adUnit === "RightColumn" ||
      adUnit === "LeftColumn" ||
      adUnit === "StickyBanner"
    );
  }

  // Function to start the timer
  function startTimer(adUnit, timerLength) {
    let timeRemaining = timerLength;
    DebugModeLog(`CR: ${adUnit}: 🚀 Timer started  `);

    // This statement checks whether there was already a timer set for this adUnit
    // It is used when a user navigates away from page and then comes back
    if (refreshedAdUnits[adUnit]?.remainingTime) {
      timeRemaining = refreshedAdUnits[adUnit].remainingTime;
    }

    refreshedAdUnits[adUnit] = {
      intervalId: setInterval(() => {
        // If ad unit comes back empty cancel timer
        if (timerTracker[adUnit].isEmpty) {
          DebugModeLog(
            `CR: ${adUnit}: ⏱️🚫 TIMER STOPPED/NOT STARTED, adunit not filled`
          );
          clearInterval(refreshedAdUnits[adUnit].intervalId);
          return;
        }

        // If ad unit is no longer in view cancel timer
        if (timerTracker[adUnit].isIntersecting === false) {
          DebugModeLog(`CR: ${adUnit}: ⏱️🚫 Not in view anymore.`);

          clearInterval(refreshedAdUnits[adUnit].intervalId);
          return;
        }

        // Run/Iterate timer
        if (!timerTracker[adUnit].isEmpty) {
          DebugModeLog(`CR: ${adUnit}: ⏱️ ${timeRemaining}`);
          if (timeRemaining <= 0 && isPageVisible) {
            DebugModeLog(
              `CR: ${adUnit}: ⏱️ Timer reached 0, initialising refresh`
            );

            initialiseRefresh(adUnit);

            // If winning bidder provided new timer set that as next timer otherwise set to default
            timeRemaining = refreshedAdUnits[adUnit].newTimer
              ? refreshedAdUnits[adUnit].newTimer
              : timerLength;
          } else {
            timeRemaining--;
          }
        }

        // Set remaining timer for next iteration
        refreshedAdUnits[adUnit].remainingTime = timeRemaining;
      }, 1000),
      // remainingTime: timeRemaining,
    };
  }

  function initialiseRefresh(adUnitName) {
    DebugModeLog(`CR: ${adUnitName}: Refresh Started`);

    runAdUnitRefresh(adUnitName);

    const winners = window.pbjs.getAllWinningBids();
    const adUnitWinners = winners.filter(
      (bidder) => bidder.adUnitCode === adUnitName
    );
    const latestWinner = adUnitWinners.length ? adUnitWinners.slice(-1)[0] : [];
    let currentBiddersLength = adUnitWinners.length;

    DebugModeLog(`CR: ${adUnitName}: 🏆 Winner: ${latestWinner["bidder"]}`);

    if (currentBiddersLength > previousBiddersLength) {
      // if currentBiddersLength is bigger than previousBiddersLength it means we have a new bid from prebid
      switch (latestWinner["bidderCode"]) {
        case "ix":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: ${adUnitName}: IX won, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        case "teads":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: ${adUnitName}: Teads won, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        case "justpremium":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: ${adUnitName}: Justpremium won, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        case "criteo":
          refreshedAdUnits[adUnitName].newTimer = longDelay;
          DebugModeLog(
            `CR: ${adUnitName}: Criteo won, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
          break;
        default:
          refreshedAdUnits[adUnitName].newTimer = defaultDelay;
          DebugModeLog(
            `CR: ${adUnitName}: Other bidder won, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
          );
      }
    } else {
      // No prebid winners
      refreshedAdUnits[adUnitName].newTimer = longDelay;
      DebugModeLog(
        `CR: ${adUnitName}: No prebid winner, delay set to: ${refreshedAdUnits[adUnitName].newTimer}ms`
      );
    }
    previousBiddersLength = adUnitWinners.length;

    window.addEventListener("assertive_logImpression", function (event) {
      if (event.data.payload.pageView_refreshCount > 0) {
        event.data.payload.custom_6 = "refreshed";
      }
    });
  }

  function checkReadyToStart() {
    DebugModeLog("CR: checkReadyToStart() Ran!");
    // Checks if timer is ready to start based on conditions
    // Function is called when adunit is rendered and becomes viewable so all adUnits will eventually start their timer.

    for (let key in timerTracker) {
      if (
        timerTracker.hasOwnProperty(key) &&
        !timerTracker[key].isTimerStarted
      ) {
        // Scrollable ads (with observer)
        if (timerTracker[key].isScrollable) {
          if (
            (!timerTracker[key].isEmpty &&
              timerTracker[key].isRendered &&
              timerTracker[key].isViewable) ||
            timerTracker[key].isIntersecting
          ) {
            DebugModeLog(`CR: ${key}: Timer Started`);

            startTimer(key, defaultDelay);
            timerTracker[key].isTimerStarted = true;
          }
        }

        // Sticky ads (no observer)
        if (!timerTracker[key].isScrollable) {
          if (
            !timerTracker[key].isEmpty &&
            timerTracker[key].isRendered &&
            timerTracker[key].isViewable
          ) {
            DebugModeLog(`CR: ${key}: Timer Started`);

            startTimer(key, defaultDelay);
            timerTracker[key].isTimerStarted = true;
          }
        }
      }
    }
  }

  function runAdUnitRefresh(adUnitName) {
    fetchAdUnitBids(adUnitName);

    setTimeout(function () {
      if (timerTracker[adUnitName].requestManager.request === false) {
        DebugModeLog(`CR: ${adUnitName}: Refresh timed out`);
        sendAdserverRequestForAdUnit(adUnitName);
      }

      timerTracker[adUnitName].requestManager.request = false;
    }, 3000);
  }

  function fetchAdUnitBids(adUnitName) {
    // This makes sure that all the parallax units work
    const isParallax = adUnitName.includes("MPU_Parallax_");
    const isLeaderDesktopInf = adUnitName.includes("LeaderDesktopInfinite_");
    const apsAdUnitName = isParallax
      ? "MPU_Parallax"
      : isLeaderDesktopInf
      ? "LeaderDesktopInfinite"
      : adUnitName;

    window.apstag.fetchBids(
      {
        slots: [window.apsAdUnits.find((ad) => ad.slotID === apsAdUnitName)],
        timeout: bidTimeout,
      },
      function (bids) {
        headerBidderBackForAdunit("a9", adUnitName);
      }
    );
    window.pbjs.que.push(() => {
      window.pbjs.requestBids({
        timeout: 2700,
        adUnits: [window.pbjs.adUnits.find((ad) => ad.code === adUnitName)],
        bidsBackHandler: function (bidResponses) {
          headerBidderBackForAdunit("prebid", adUnitName);
        },
      });
    });

    DebugModeLog(`CR: ${adUnitName}: Bids Requested`);
  }

  function bidBackForAdunit(adunit) {
    return (
      timerTracker[adunit].requestManager["a9"] &&
      timerTracker[adunit].requestManager["prebid"]
    );
  }

  function headerBidderBackForAdunit(bidder, adunit) {
    // return early if request to adserver is already sent

    DebugModeLog("Bids back for " + bidder + " and adunit " + adunit);

    if (timerTracker[adunit].requestManager["request"] === true) {
      return;
    }

    timerTracker[adunit].requestManager[bidder] = true;

    // if all bidders are back, send the request to the ad server
    if (bidBackForAdunit(adunit)) {
      DebugModeLog("Bids back?", timerTracker[adunit].requestManager);
      sendAdserverRequestForAdUnit(adunit);
    }
  }

  function sendAdserverRequestForAdUnit(adunit) {
    // return early if adunit request sent
    if (timerTracker[adunit].requestManager["request"] === true) {
      return;
    }

    // flip the boolean that keeps track of whether the adserver request was sent requestManager.adserverRequestSent = true;
    // flip pbjs boolean to signal to pbjs the ad server has already been called pbjs.adserverRequestSent = true;

    // flip boolean for adserver request to avoid duplicate requests
    timerTracker[adunit].requestManager["request"] = true;

    // set bid targeting and make ad request to GAM
    window.googletag.cmd.push(function () {
      //key values
      window.ads[adunit].setTargeting("refresh", "1");
      window.apstag.setDisplayBids([adunit]);
      window.pbjs.setTargetingForGPTAsync([adunit]);
      window.googletag.pubads().refresh([window.ads[adunit]]);

      DebugModeLog("Refresh complete for: ", adunit);
    });

    ClearRefresh(adunit);
  }

  function ClearRefresh(adunitName) {
    timerTracker[adunitName].requestManager.a9 = false;
    timerTracker[adunitName].requestManager.prebid = false;
  }
};
